import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { IEvent } from '../Events';
import { useStyles } from '../Events.styles';

interface ISavedEvents {
    events: (IEvent & { id: string })[];
    onDelete: (id: string) => void;
    onOpen: (event: IEvent & { id: string }) => void;
}

export const SavedEvents = ({ events, onDelete, onOpen }: ISavedEvents) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box className={classes.savedEventsContainer}>
            <Typography variant="h4" gutterBottom>
                {t('Events.savedEvents')}
            </Typography>
            {events.length === 0 && <Typography>{t('Events.noEvents')}</Typography>}
            <List>
                {events?.map(event => (
                    <ListItem key={event.id} divider>
                        <ListItemText
                            primary={<Typography variant="h6">{event.name || t('Events.eventName')}</Typography>}
                            secondary={
                                <>
                                    <Typography component="span" variant="body2" color="textPrimary">
                                        {event.location || t('Events.eventLocation')}
                                    </Typography>
                                    <br />
                                    <Typography component="span" variant="body2" color="textSecondary">
                                        {t('Events.totalTeams', {
                                            count: event.teams?.length || 0,
                                        })}
                                    </Typography>
                                    <br />
                                    <Typography component="span" variant="body2" color="textSecondary">
                                        {t('Events.creationDate', {
                                            date: format(new Date(event.createdAt), 'dd.MM.yy'),
                                            hour: format(new Date(event.createdAt), 'HH:mm'),
                                        })}
                                    </Typography>
                                </>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="open"
                                onClick={() => onOpen(event)}
                                className={classes.savedEventsEditButton}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => onDelete(event.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};
