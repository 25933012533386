import React from 'react';
import { Navigate } from 'react-router';
import { pages } from '../../constans/location';
import { Roles } from '../../constans/settings';
import { RolesContext } from '../RolesContext/RolesContext';

interface IProtectedRouteProps {
    children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
    const { role } = React.useContext(RolesContext);

    if (role === Roles.ADMIN) {
        return <>{children}</>;
    }

    return <Navigate to={pages.MAIN} />;
};
