import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { IEventTeam } from '../Events';
import { useStyles } from '../Events.styles';

interface ITeamCard {
    team: IEventTeam;
    handleDelete: (id: string) => void;
    handleEditDrawerOpen: (team: IEventTeam) => void;
}

export const TeamCard = ({ team, handleDelete, handleEditDrawerOpen }: ITeamCard) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Card sx={{ marginBottom: 2 }}>
            <CardContent className={classes.teamsContent}>
                <Box>
                    <Typography variant="h6">{team.name}</Typography>
                    <DialogContentText>
                        {t('Events.teamClub')}: {team.club?.name}
                    </DialogContentText>
                    <DialogContentText>
                        {t('Events.teamCaptain')}: {team.players?.find(player => player.isCaptain)?.name || '  '}
                    </DialogContentText>
                    <DialogContentText>
                        {t('Events.totalPlayers')}: {team.players?.length}
                    </DialogContentText>
                </Box>
                <Box>
                    <IconButton aria-label="edit" onClick={() => handleEditDrawerOpen(team)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDelete(team.uniqueId)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
};
