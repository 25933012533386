export const regions = [
    {
        id: 'CK',
        en: "Cherkas`ka Oblast'",
        uk: 'Черкаська область',
    },
    {
        id: 'CH',
        en: "Chernihivs'ka Oblast'",
        uk: 'Чернігівська область',
    },
    {
        id: 'CV',
        en: "Chernivets'ka Oblast'",
        uk: 'Чернівецька область',
    },
    {
        id: 'KR',
        en: 'Autonomous Republic of Krym',
        uk: 'Автономна Республіка Крим',
    },
    {
        id: 'DP',
        en: "Dnipropetrovs'ka Oblast'",
        uk: 'Дніпропетровська область',
    },
    {
        id: 'DT',
        en: "Donets'ka Oblast'",
        uk: 'Донецька область',
    },
    {
        id: 'IF',
        en: "Ivano-Frankivs'ka Oblast'",
        uk: 'Івано-Франківська область',
    },
    {
        id: 'KK',
        en: "Kharkivs'ka Oblast'",
        uk: 'Харківська область',
    },
    {
        id: 'KS',
        en: "Khersons'ka Oblast'",
        uk: 'Херсонська область',
    },
    {
        id: 'KM',
        en: "Khmel'nyts'ka Oblast'",
        uk: 'Хмельницька область',
    },
    {
        id: 'KV',
        en: "Kyivs'ka Oblast'",
        uk: 'Київська область',
    },
    {
        id: 'KC',
        en: 'Kyiv City',
        uk: 'місто Київ',
    },
    {
        id: 'KH',
        en: "Kirovohrads'ka Oblast'",
        uk: 'Кіровоградська область',
    },
    {
        id: 'LH',
        en: "Luhans'ka Oblast'",
        uk: 'Луганська область',
    },
    {
        id: 'LV',
        en: "L'vivs'ka Oblast'",
        uk: 'Львівська область',
    },
    {
        id: 'MY',
        en: "Mykolaivs'ka Oblast'",
        uk: 'Миколаївська область',
    },
    {
        id: 'OD',
        en: "Odes'ka Oblast'",
        uk: 'Одеська область',
    },
    {
        id: 'PL',
        en: "Poltavs'ka Oblast'",
        uk: 'Полтавська область',
    },
    {
        id: 'RV',
        en: "Rivnens'ka Oblast'",
        uk: 'Рівненська область',
    },
    {
        id: 'SC',
        en: "Sevastopol' City",
        uk: 'місто Севастополь',
    },
    {
        id: 'SM',
        en: "Sums'ka Oblast'",
        uk: 'Сумська область',
    },
    {
        id: 'TP',
        en: "Ternopil's'ka Oblast'",
        uk: 'Тернопільська область',
    },
    {
        id: 'ZK',
        en: "Zakarpats'ka Oblast'",
        uk: 'Закарпатська область',
    },
    {
        id: 'VI',
        en: "Vinnyts'ka Oblast'",
        uk: 'Вінницька область',
    },
    {
        id: 'VO',
        en: "Volyns'ka Oblast'",
        uk: 'Волинська область',
    },
    {
        id: 'ZP',
        en: "Zaporiz'ka Oblast'",
        uk: 'Запорізька область',
    },
    {
        id: 'ZT',
        en: "Zhytomyrs'ka Oblast'",
        uk: 'Житомирська область',
    },
];
