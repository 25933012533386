import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

interface IConfirmationDrawer {
    cancelButtonText: string;
    confirmButtonText: string;
    isOpen: boolean;
    text: string;
    onCancel: () => void;
    onConfirm: () => void;
}

export const ConfirmationDrawer = ({
    confirmButtonText,
    cancelButtonText,
    isOpen,
    text,
    onCancel,
    onConfirm,
}: IConfirmationDrawer) => {
    return (
        <Dialog open={isOpen} onClose={onCancel}>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    {cancelButtonText}
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained" autoFocus>
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
