export const intl = {
    en: {
        'Floorball.title': 'Floorball Ukraine',
        'Floorball.clubs': 'Clubs',
        'Floorball.players': 'Players',
        'Floorball.transfers': 'Transfers',
        'Floorball.events': 'Events',
        'Floorball.login': 'Login with Google',
        'Floorball.logout': 'Log out',
        'Floorball.locale': 'Українська',
        'Floorball.main': 'Main',
        'Floorball.newClub': 'New Club',
        'Floorball.editClub': 'Edit Club',
        'Floorball.newPlayer': 'New Player',
        'Floorball.editPlayer': 'Edit Player',
        'Floorball.newTransfer': 'New Transfer',
        'Floorball.startLicenses': 'Start licenses',
        'Floorball.continueLicenses': 'Renew licenses',
        'Floorball.form.required': 'This field is required',
        'Floorball.form.save': 'Save',
        'Floorball.form.success': 'Done!',
    },
    uk: {
        'Floorball.title': 'Флорбол України',
        'Floorball.clubs': 'Клуби',
        'Floorball.players': 'Гравці',
        'Floorball.transfers': 'Трансфери',
        'Floorball.events': ' Події',
        'Floorball.login': 'Увійти з гугл',
        'Floorball.logout': 'Вийти',
        'Floorball.locale': 'English',
        'Floorball.main': 'Головна',
        'Floorball.newClub': 'Новий клуб',
        'Floorball.editClub': 'Редагувати клуб',
        'Floorball.newPlayer': 'Новий гравець',
        'Floorball.editPlayer': 'Редагувати гравця',
        'Floorball.newTransfer': 'Новий трансфер',
        'Floorball.startLicenses': 'Нові ліцензії',
        'Floorball.continueLicenses': 'Продовжити ліцензії',
        'Floorball.form.required': "Це поле обов'язкове",
        'Floorball.form.save': 'Зберегти',
        'Floorball.form.success': 'Готово!',
    },
};
