const intl = {
    en: {
        'Auth.createClub': 'Create a club',
        'Auth.login': 'Login',
        'Auth.logout': 'Logout',
    },
    uk: {
        'Auth.createClub': 'Створити клуб',
        'Auth.login': 'Увійти',
        'Auth.logout': 'Вийти',
    },
};

export { intl };
