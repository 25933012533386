import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';

import EditClubInfo from '../Clubs/EditClubInfo/EditClubInfo';
import ClubInfo from './ClubInfo/ClubInfo';
import ClubsList from './ClubsList/ClubsList';

import { getDatabase, ref } from 'firebase/database';
import { useObject } from 'react-firebase-hooks/database';
import { firebaseApp } from '../../firebaseInit';

import { pages } from '../../constans/location';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import { RolesContext } from '../RolesContext/RolesContext';

const database = getDatabase(firebaseApp);

export interface IClub {
    email: string;
    fullNameInt: string;
    fullName: string;
    phone: string;
    photo: string;
    shortNameInt: string;
    shortName: string;
    url: string;
    city: string;
    line: string;
    postCode: string;
    country: string;
    region: string;
    founded: number;
    added: number;
}

export default function Clubs() {
    const [snapshotClubs, loadingClubs, errorClubs] = useObject(ref(database, 'clubs'));
    const [snapshotImages, loadingImages, errorImages] = useObject(ref(database, 'images'));
    const { role } = React.useContext(RolesContext);

    useObject(ref(database, 'counters'));

    if (loadingClubs || loadingImages) {
        return <LinearProgress />;
    }

    if (errorClubs || errorImages) {
        return <div>Error: {errorClubs || errorImages}</div>;
    }

    const clubs = snapshotClubs?.val();
    const images = snapshotImages?.val();

    return (
        <Routes>
            <Route index element={<ClubsList images={images} clubs={clubs} />} />
            <Route path={pages.CLUB_INFO} element={<ClubInfo images={images} clubs={clubs} role={role} />} />
            <Route
                path={pages.EDIT_CLUB}
                element={
                    <ProtectedRoute>
                        <EditClubInfo images={images} clubs={clubs} />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
}
