import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { pages } from '../../constans/location';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupIcon from '@mui/icons-material/Group';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import TranslateIcon from '@mui/icons-material/Translate';

import { useStyles } from './Floorball.styles';

import Auth from '../Auth/Auth';
import Clubs from '../Clubs/Clubs';
import Events from '../Events/Events';
import Info from '../Info/Info';
import Players from '../Players/Players';
import Transfers from '../Transfers/Transfers';
import { RolesContext } from '../RolesContext/RolesContext';
import { Roles } from '../../constans/settings';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';

function NotFound() {
    return <h2>Easy, this page not alowed for now.</h2>;
}

const suportedLanguagesMap = {
    en: 'en',
    uk: 'uk',
};

function Root(): JSX.Element {
    const navigate = useNavigate();

    navigate(pages.CLUBS);

    return <></>;
}

export default function Floorball() {
    const navigate = useNavigate();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const { role } = React.useContext(RolesContext);

    const openTransltor = Boolean(anchorEl);
    const handleClose = (event: React.KeyboardEvent | React.MouseEvent) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleTranslateClick = useCallback(
        (event: React.KeyboardEvent | React.MouseEvent) => {
            event.stopPropagation();

            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleDrawerOpen = useCallback(
        event => {
            event.stopPropagation();
            setOpen(true);
        },
        [setOpen]
    );

    const handleDrawerClose = useCallback(
        event => {
            event.stopPropagation();
            setOpen(false);
        },
        [setOpen]
    );

    const handleMenuItemClick = (path: string) => () => navigate(path);

    const handleLanguageChange = (value: string) => () => {
        i18n.changeLanguage(value);
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        id="drawer-open-button"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {t('Floorball.title')}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            color="inherit"
                            id="translate-button"
                            aria-controls="translate-menu"
                            aria-haspopup="true"
                            aria-expanded={openTransltor ? 'true' : undefined}
                            onClick={handleTranslateClick}
                        >
                            <TranslateIcon />
                        </IconButton>
                        <Menu
                            id="translate-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={openTransltor}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleLanguageChange(suportedLanguagesMap.en)} disableRipple>
                                Eng
                            </MenuItem>
                            <MenuItem onClick={handleLanguageChange(suportedLanguagesMap.uk)} disableRipple>
                                Укр
                            </MenuItem>
                        </Menu>
                        <Auth />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose} size="large">
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItemButton onClick={handleMenuItemClick(`${pages.CLUBS}`)}>
                        <ListItemIcon>
                            <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Floorball.clubs')} />
                    </ListItemButton>
                    <ListItemButton onClick={handleMenuItemClick(`${pages.PLAYERS}`)}>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Floorball.players')} />
                    </ListItemButton>
                    <ListItemButton onClick={handleMenuItemClick(`${pages.TRANSFERS}`)}>
                        <ListItemIcon>
                            <TransferWithinAStationIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Floorball.transfers')} />
                    </ListItemButton>
                    {role === Roles.ADMIN && (
                        <ListItemButton onClick={handleMenuItemClick(`${pages.EVENTS}`)}>
                            <ListItemIcon>
                                <EmojiEventsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('Floorball.events')} />
                        </ListItemButton>
                    )}
                </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                <Routes>
                    <Route path="/*" element={<Clubs />} />
                    <Route path={`${pages.CLUBS}//*`} element={<Clubs />} />
                    <Route path={`${pages.PLAYERS}//*`} element={<Players />} />
                    <Route path={`${pages.TRANSFERS}//*`} element={<Transfers />} />
                    <Route path={pages.INFO} element={<Info />} />
                    <Route
                        path={pages.EVENTS}
                        element={
                            <ProtectedRoute>
                                <Events />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
        </div>
    );
}
