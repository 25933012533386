import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    teamsContainer: {
        maxHeight: '528px',
        overflowY: 'auto',
    },
    teamsContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    addButton: {
        width: '25%',
    },
    licenseCheckbox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    savedEventsContainer: {
        marginTop: '32px',
    },
    savedEventsEditButton: {
        marginRight: '8px',
    },
}));
