export const intl = {
    en: {
        'FileUploader.error.sizeLimitExceeded': 'File size is to big, must be less than {{size}}MB',
        'FileUploader.buttonText': 'Upload',
    },
    uk: {
        'FileUploader.error.sizeLimitExceeded': 'Розмір файлу занадто великий, має бути менше {{size}}МБ',
        'FileUploader.buttonText': 'Завантажити',
    },
};
