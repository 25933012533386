export enum db_paths {
    Images = 'images',
    Users = 'users',
    Clubs = 'clubs',
    Players = 'players',
    Counters = 'counters',
    Transfers = 'transfers',
    CountersPlayersID = 'counters/playersID',
    Events = 'events',
}
