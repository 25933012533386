export const intl = {
    en: {
        'Events.createEvent': 'Create an event',
        'Events.createTitle': 'Create event',
        'Events.close': 'Close',
        'Events.export': 'Export as JSON',
        'Events.eventName': 'Event name',
        'Events.eventLocation': 'Event location',
        'Events.addTeam': 'Add team',
        'Events.editTeam': 'Edit team',
        'Events.teamName': 'Team name',
        'Events.teamId': 'Team Id',
        'Events.save': 'Save',
        'Events.edit': 'Edit',
        'Events.chooseClub': 'Choose club',
        'Events.teamClub': 'Club',
        'Events.teamCaptain': 'Team captain',
        'Events.totalPlayers': 'Total players',
        'Events.isChosen': 'Choose',
        'Events.playerName': 'Player name',
        'Events.playerYear': 'Year',
        'Events.playerLicense': 'License',
        'Events.isGoalkeeper': 'Glk',
        'Events.isCaptain': 'Cap',
        'Events.playerNumber': 'Player number',
        'Events.playerExpired': 'Player license expired',
        'Events.playerInAnotherTeam': 'Player is already in another team',
        'Events.totalSelectedPlayers': 'Total selected: {{count}} players',
        'Events.addOfficials': 'Add officials',
        'Events.editOfficials': 'Edit officials',
        'Events.officialName': 'Official name',
        'Events.officialSurname': 'Official surname',
        'Events.totalTeams': 'Total: {{count}} teams',
        'Events.numberTaken': 'Number is taken',
        'Events.showLicensedPlayers': 'Show only players with license',
        'Events.searchPlayer': 'Search player',
        'Events.maxYearOfBirth': 'Maximum birth year',
        'Events.savedEvents': 'Events:',
        'Events.editTitle': 'Edit event',
        'Events.noEvents': 'There are no saved events',
        'Events.action.success': 'Action was completed successfully',
        'Events.action.error': 'There was an error with your action',
        'Events.action.null': '',
        'Events.creationDate': 'Created: {{date}} at {{hour}}',
        'Events.delete.confirm': 'Delete',
        'Events.cancel': 'Cancel',
        'Events.delete.text': 'Are you sure you want to delete this event?',
    },
    uk: {
        'Events.createEvent': 'Створити подію',
        'Events.createTitle': 'Створити подію',
        'Events.close': 'Закрити',
        'Events.export': 'Експортувати як JSON',
        'Events.eventName': 'Назва події',
        'Events.eventLocation': 'Місце проведення події',
        'Events.addTeam': 'Додати команду',
        'Events.editTeam': 'Редагувати команду',
        'Events.teamName': 'Назва команди',
        'Events.teamId': 'ID команди',
        'Events.save': 'Зберегти',
        'Events.edit': 'Редагувати',
        'Events.chooseClub': 'Обрати клуб',
        'Events.teamClub': 'Клуб',
        'Events.teamCaptain': 'Капітан команди',
        'Events.totalPlayers': 'Всього гравців',
        'Events.isChosen': 'Обрати',
        'Events.playerName': "Ім'я гравця",
        'Events.playerYear': 'Рік',
        'Events.playerLicense': 'Ліцензія',
        'Events.isGoalkeeper': 'Глк',
        'Events.isCaptain': 'Кап',
        'Events.playerNumber': 'Номер гравця',
        'Events.playerExpired': 'Ліцензія гравця закінчилася',
        'Events.playerInAnotherTeam': 'Гравець вже є у іншій команді',
        'Events.totalSelectedPlayers': 'Всього вибрано: {{count}} гравців',
        'Events.addOfficials': 'Додати суддів',
        'Events.editOfficials': 'Редагувати суддів',
        'Events.officialName': "Ім'я судді",
        'Events.officialSurname': 'Прізвище судді',
        'Events.totalTeams': 'Всього: {{count}} команд',
        'Events.numberTaken': 'Номер вже використовується',
        'Events.showLicensedPlayers': 'Показати тільки гравців з ліцензією',
        'Events.searchPlayer': 'Пошук гравця',
        'Events.maxYearOfBirth': 'Макс',
        'Events.savedEvents': 'Події:',
        'Events.editTitle': 'Редагувати подію',
        'Events.noEvents': 'Немає збережених подій',
        'Events.action.success': 'Запит успішно виконано',
        'Events.action.error': 'Сталася помилка при відправці запиту',
        'Events.action.null': '',
        'Events.creationDate': 'Створено: {{date}} о {{hour}}',
        'Events.delete.confirm': 'Видалити',
        'Events.cancel': 'Відмінити',
        'Events.delete.text': 'Ви впевнені що хочете видалити цю подію?',
    },
};
