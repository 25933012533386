import { useState } from 'react';
import { useObject } from 'react-firebase-hooks/database';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Alert, { AlertColor } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';

import AddIcon from '@mui/icons-material/Add';

import { DataSnapshot, child, getDatabase, push, ref, remove, update } from 'firebase/database';
import { db_paths } from '../../db/db_constans';
import { firebaseApp } from '../../firebaseInit';

import { IClub } from '../Clubs/Clubs';
import { ConfirmationDrawer } from '../ConfirmationDrawer/ConfirmationDrawer';
import { IPlayer } from '../Players/Players';
import { handleExport } from './CompetitionManager';
import { useStyles } from './Events.styles';
import { SavedEvents } from './SavedEvents/SavedEvents';
import { TeamCard } from './TeamCard/TeamCard';
import { TeamDrawer } from './TeamDrawer/TeamDrawer';

export interface IEvent {
    name: string;
    location: string;
    teams: IEventTeam[];
    createdAt: number;
}

export interface IEventTeam {
    id: number;
    name: string;
    players: IEventPlayer[];
    club: IEventClub;
    uniqueId: string;
}

export interface IEventClub {
    id: string;
    name: string;
}

export interface IEventPlayer {
    isChosen: boolean;
    id: string;
    name: string;
    licenseNumber: number;
    isGoalkeeper: boolean;
    isCaptain: boolean;
    isExpired: boolean;
    number: string;
    bdat: number;
}

const initialValues: IEvent = {
    name: '',
    location: '',
    teams: [],
    createdAt: 0,
};

const database = getDatabase(firebaseApp);

const NOW = new Date();

export default function Events() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [message, setMessage] = useState<AlertColor | null>(null);
    const [eventDrawer, setEventDrawer] = useState<'create' | 'edit' | null>(null);
    const [teamDrawer, setTeamDrawer] = useState<'edit' | 'create' | null>(null);
    const [confirmationDrawer, setConfirmationDrawer] = useState<'delete' | null>(null);
    const [selectedTeam, setSelectedTeam] = useState<IEventTeam | null>(null);
    const [id, setId] = useState<string | null>(null);
    const [deleteEventId, setDeleteEventId] = useState<string | null>(null);

    const { control, watch, setValue, resetField, reset, handleSubmit } = useForm<IEvent>({
        defaultValues: initialValues,
    });

    const [snapshotClubs, loadingClubs, errorClubs] = useObject(ref(database, 'clubs'));
    const [snapshotPlayers, loadingPlayers, errorPlayers] = useObject(ref(database, 'players'));
    const [snapshotEvents, loadingEvents, errorEvents] = useObject(ref(database, 'events'));

    if (loadingClubs || loadingPlayers || loadingEvents) {
        return <LinearProgress />;
    }

    if (errorClubs || errorPlayers || errorEvents) {
        return <div>Error: {errorClubs || errorPlayers}</div>;
    }

    const clubs = getClubs(snapshotClubs);
    const players = getPlayers(snapshotPlayers);
    const events = getEvents(snapshotEvents);
    const teams = watch('teams');

    const createPlayersList = (clubId: string, selectedPlayers: IEventPlayer[] | null): IEventPlayer[] => {
        const filteredPlayers = players.filter(player => player.currentClub === clubId);
        const transformedPlayers = transformPlayers(filteredPlayers, selectedPlayers);

        return transformedPlayers;
    };

    // Alert

    const handleAlertClose = () => setMessage(null);

    // Event Modal handlers

    const handleEventCreateDrawerOpen = () => {
        reset();
        setEventDrawer('create');
    };

    const handleEventEditDrawerOpen = (event: IEvent & { id: string }) => {
        setEventDrawer('edit');
        setValue('name', event.name || '');
        setValue('location', event.location || '');
        setValue('teams', event.teams || []);
        setValue('createdAt', event.createdAt);
        setId(event.id);
    };

    const handleEventDrawerClose = () => {
        setEventDrawer(null);
        resetTeamFields();
        setId(null);
    };

    // Team Modal handlers

    const handleTeamCreateDrawerOpen = () => {
        setTeamDrawer('create');
    };

    const handleTeamEditDrawerOpen = (team: IEventTeam) => {
        setSelectedTeam(team);
        setTeamDrawer('edit');
    };

    const handleCloseTeamDrawer = () => setTeamDrawer(null);

    // Confirmation Modal handlers

    const handleConfirmationDrawerDeleteOpen = () => setConfirmationDrawer('delete');

    const handleConfirmationDrawerClose = () => setConfirmationDrawer(null);

    // Resets

    const resetTeamFields = () => {
        resetField('name');
        resetField('location');
    };

    // Team actions

    const handleCreateOrEditTeam = (team: IEventTeam) => {
        switch (teamDrawer) {
            case 'create': {
                const teamWithId = { ...team, uniqueId: uuidv4() };
                const newTeams = [...teams, teamWithId];
                setValue('teams', newTeams);
                break;
            }
            case 'edit':
                {
                    const updatedTeams = teams.map(t => (t.uniqueId === team.uniqueId ? team : t));
                    setValue('teams', updatedTeams);
                    setSelectedTeam(null);
                }
                break;
        }
        handleCloseTeamDrawer();
    };

    const handleDeleteTeam = (id: string) => {
        const updatedTeams = teams.filter(team => team.uniqueId !== id);
        setValue('teams', updatedTeams);
    };

    // Event actions

    const handleSaveOrEditEvent = async (event: IEvent) => {
        const updates: Partial<Record<string, IEvent>> = {};
        let eventId = id;

        if (!id) {
            eventId = push(child(ref(database), db_paths.Events)).key || '';
            event.createdAt = new Date().valueOf();
        }

        updates[`/${db_paths.Events}/${eventId}`] = {
            ...event,
        };

        console.info('update', updates);

        update(ref(database), updates)
            .then(() => {
                setMessage('success');
            })
            .catch(() => {
                setMessage('error');
            });
    };

    const handleDeleteEvent = (id: string) => {
        handleConfirmationDrawerDeleteOpen();
        setDeleteEventId(id);
    };

    const confirmDeleteEvent = async () => {
        if (deleteEventId) {
            remove(ref(database, `${db_paths.Events}/${deleteEventId}`))
                .then(() => {
                    setMessage('success');
                })
                .catch(() => {
                    setMessage('error');
                })
                .finally(() => {
                    setDeleteEventId(null);
                    handleConfirmationDrawerClose();
                });
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleEventCreateDrawerOpen}>
                {t('Events.createEvent')}
            </Button>
            <SavedEvents events={events} onDelete={handleDeleteEvent} onOpen={handleEventEditDrawerOpen} />
            <Dialog open={eventDrawer !== null} fullWidth maxWidth="lg">
                <DialogTitle>{eventDrawer === 'create' ? t('Events.createTitle') : t('Events.editTitle')}</DialogTitle>
                <form>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label={t('Events.eventName')}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="location"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label={t('Events.eventLocation')}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={handleTeamCreateDrawerOpen}
                                    className={classes.addButton}
                                >
                                    {t('Events.addTeam')}
                                </Button>
                            </Grid>
                            {teams.length > 0 && (
                                <Grid item xs={12}>
                                    <Box className={classes.teamsContainer}>
                                        {teams.map(team => (
                                            <TeamCard
                                                key={team.uniqueId}
                                                team={team}
                                                handleDelete={handleDeleteTeam}
                                                handleEditDrawerOpen={handleTeamEditDrawerOpen}
                                            />
                                        ))}
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <DialogContentText>
                                    {
                                        t('Events.totalTeams', {
                                            count: teams.length,
                                        }) as string
                                    }
                                </DialogContentText>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEventDrawerClose} color="primary">
                            {t('Events.close')}
                        </Button>
                        <Button onClick={handleSubmit(handleSaveOrEditEvent)} color="primary" variant="contained">
                            {eventDrawer === 'create' ? t('Events.save') : t('Events.edit')}
                        </Button>
                        <Button onClick={handleSubmit(handleExport)} color="primary" variant="outlined" type="submit">
                            {t('Events.export')}
                        </Button>
                    </DialogActions>
                </form>
                <TeamDrawer
                    drawer={teamDrawer}
                    clubs={clubs}
                    teams={teams}
                    createPlayersList={createPlayersList}
                    handleAction={handleCreateOrEditTeam}
                    handleDrawerClose={handleCloseTeamDrawer}
                    {...(teamDrawer === 'edit' && { selectedTeam })}
                />
            </Dialog>
            <ConfirmationDrawer
                confirmButtonText={t(`Events.delete.confirm`)}
                cancelButtonText={t('Events.cancel')}
                isOpen={confirmationDrawer !== null}
                text={t('Events.delete.text')}
                onCancel={handleConfirmationDrawerClose}
                onConfirm={confirmDeleteEvent}
            />
            <Snackbar open={message !== null} autoHideDuration={3000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} elevation={6} variant="filled" severity={message || 'success'}>
                    {t(`Events.action.${message}`)}
                </Alert>
            </Snackbar>
        </>
    );
}

function getClubs(snapshotClubs: DataSnapshot | undefined) {
    const clubs: Record<string, IClub> = snapshotClubs?.val();
    const clubsArray: IEventClub[] = clubs
        ? Object.entries(clubs)
              .map(([id, clubData]: [string, IClub]) => ({
                  name: clubData.fullName,
                  id,
              }))
              .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        : [];

    return clubsArray;
}

function getPlayers(snapshotPlayers: DataSnapshot | undefined) {
    const players: Record<string, IPlayer> = snapshotPlayers?.val();
    const playersArray = Object.entries(players || {})
        .map(([id, playerData]: [string, IPlayer]) => ({
            ...playerData,
            id,
        }))
        .sort((a, b) => {
            const nameA = `${a.lastName} ${a.firstName}`.toLowerCase();
            const nameB = `${b.lastName} ${b.firstName}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });

    return playersArray;
}

function getEvents(snapshotEvents: DataSnapshot | undefined) {
    const events: Record<string, IEvent> = snapshotEvents?.val();
    const eventsArray = Object.entries(events || {}).map(([id, eventData]: [string, IEvent]) => ({
        ...eventData,
        id,
    }));

    return eventsArray;
}

function transformPlayers(players: (IPlayer & { id: string })[], selectedPlayers: IEventPlayer[] | null) {
    const transformedPlayers = players.map(player => {
        const selectedPlayer = selectedPlayers?.find(selectedPlayer => selectedPlayer.id === player.id);
        return {
            isChosen: !!selectedPlayer,
            id: player.id,
            name: `${player.lastName} ${player.firstName}`,
            licenseNumber: player.license,
            isGoalkeeper: selectedPlayer ? selectedPlayer.isGoalkeeper : false,
            isCaptain: selectedPlayer ? selectedPlayer.isCaptain : false,
            isExpired: player.endActivationDate <= NOW.valueOf(),
            number: selectedPlayer ? selectedPlayer.number : '',
            bdat: player.born,
        };
    });

    return transformedPlayers;
}
