import { SubmitHandler } from 'react-hook-form';
import slugify from 'slugify';

import { IEvent } from './Events';

interface ICompetitionOfficials {
    name: string;
    surname: string;
    type: string;
}

interface ICompetitionTeam {
    id: number;
    name: string;
    plrs: ICompetitionTeamPlayer[];
}

interface ICompetitionTeamPlayer {
    id: number;
    num: string;
    glkp?: boolean | undefined;
    cap?: boolean | undefined;
}

interface ICompetitionPlayers {
    id: number;
    name: string;
    sur: string;
    bdat: string;
}

export interface ICompetitionManager {
    competitionManager: {
        dataType: string;
    };
    info: {
        name: string;
        location: string;
        sec_number: number | null;
        officials: ICompetitionOfficials[];
    };
    teams: ICompetitionTeam[];
    players: ICompetitionPlayers[];
}

export function downloadJson<T>(data: T, exportName: string) {
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${exportName}.json`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

function createTeamsToExport(data: IEvent) {
    const teamsToExport: ICompetitionTeam[] = data.teams
        .map(team => ({
            id: team.id,
            name: team.name,
            plrs: team.players.map(player => ({
                id: player.licenseNumber,
                num: player.number,
                ...(player.isGoalkeeper && { glkp: true }),
                ...(player.isCaptain && { cap: true }),
            })),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    return teamsToExport;
}

function createPlayersToExport(data: IEvent) {
    const playersToExport: ICompetitionPlayers[] = data.teams
        .flatMap(team =>
            team.players.map(player => {
                const [lastName, firstName] = player.name.split(' ');
                const birthDate = new Date(player.bdat);
                return {
                    id: player.licenseNumber,
                    name: firstName,
                    sur: lastName,
                    bdat: birthDate.toISOString().split('T')[0],
                };
            })
        )
        .sort((a, b) => a.sur.localeCompare(b.sur));

    return playersToExport;
}

function createExportFile(data: IEvent) {
    const teamsToExport = createTeamsToExport(data);
    const playersToExport = createPlayersToExport(data);

    const toExport: ICompetitionManager = {
        competitionManager: {
            dataType: 'teams',
        },
        info: {
            name: data.name,
            location: data.location,
            sec_number: null,
            officials: [
                {
                    type: 'sec',
                    name: 'Павло',
                    surname: 'Пелех',
                },
                {
                    type: '',
                    name: ' ',
                    surname: ' ',
                },
                {
                    type: '',
                    name: ' ',
                    surname: ' ',
                },
            ],
        },
        teams: teamsToExport,
        players: playersToExport,
    };

    return toExport;
}

export const handleExport: SubmitHandler<IEvent> = (data: IEvent) => {
    const toExport = createExportFile(data);

    const NOW = new Date();
    const formattedDate = NOW.toISOString();

    const exportName = `${slugify(data.name, '_')}_${formattedDate}`;

    downloadJson<ICompetitionManager>(toExport, exportName);
};
