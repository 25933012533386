import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';

import { IEventPlayer, IEventTeam } from '../Events';
import { getYearOfBirth } from '../TeamDrawer/TeamDrawer';

interface IPlayersTable {
    clubId: string;
    originalPlayers: IEventPlayer[];
    page: number;
    players: IEventPlayer[];
    rowsPerPage: number;
    selectedTeam?: IEventTeam | null;
    teams: IEventTeam[];
    setOriginalPlayers: (players: IEventPlayer[]) => void;
    setPlayers: (player: IEventPlayer[]) => void;
    handleChangePage: (event: unknown, page: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PlayersTable = ({
    clubId,
    originalPlayers,
    page,
    players,
    rowsPerPage,
    selectedTeam,
    teams,
    setOriginalPlayers,
    handleChangePage,
    handleChangeRowsPerPage,
    setPlayers,
}: IPlayersTable) => {
    const { t } = useTranslation();
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [sortColumn, setSortColumn] = useState<keyof IEventPlayer>('isChosen');

    const rowsPerPageOptions = [5, 15, 25];

    const handleSort = (column: keyof IEventPlayer) => {
        const isAsc = sortColumn === column && sortOrder === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';

        setSortOrder(newOrder);
        setSortColumn(column);

        const sortedPlayers = sortPlayers(players, newOrder, column);
        setPlayers(sortedPlayers);
    };

    const handleNumberChange = (id: string, value: string) => {
        const changedPlayers = changePlayerNumber(originalPlayers, id, value);

        setOriginalPlayers(changedPlayers);
        setPlayers(changedPlayers);
    };

    const handleMultipleCheckboxChange = (id: string, key: keyof IEventPlayer) => {
        const newPlayers = originalPlayers.map(player =>
            player.id === id ? { ...player, [key]: !player[key] } : player
        );

        setOriginalPlayers(newPlayers);
        setPlayers(newPlayers);
    };

    const handleUniqueCheckboxChange = (id: string, key: keyof IEventPlayer) => {
        const isCurrentlyChecked = originalPlayers.some(player => player.id === id && player[key]);

        const newPlayers = originalPlayers.map(player => ({
            ...player,
            [key]: player.id === id ? !isCurrentlyChecked : false,
        }));

        setOriginalPlayers(newPlayers);
        setPlayers(newPlayers);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'isChosen'}
                                direction={sortOrder}
                                onClick={() => handleSort('isChosen')}
                            >
                                {t('Events.isChosen')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'name'}
                                direction={sortOrder}
                                onClick={() => handleSort('name')}
                            >
                                {t('Events.playerName')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">{t('Events.playerYear')}</TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={sortColumn === 'licenseNumber'}
                                direction={sortOrder}
                                onClick={() => handleSort('licenseNumber')}
                            >
                                {t('Events.playerLicense')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">{t('Events.isGoalkeeper')}</TableCell>
                        <TableCell align="center">{t('Events.isCaptain')}</TableCell>
                        <TableCell>{t('Events.playerNumber')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(player => (
                        <TableRow key={player.id}>
                            <TableCell sx={{ width: '106px' }}>
                                <Checkbox
                                    checked={player.isChosen}
                                    onChange={() => handleMultipleCheckboxChange(player.id, 'isChosen')}
                                />
                            </TableCell>
                            <TableCell>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    {player.name}
                                    <Box display="flex" alignItems="center">
                                        {player.isExpired && (
                                            <Tooltip title={t('Events.playerExpired') as string}>
                                                <ErrorOutlineIcon color="error" fontSize="small" />
                                            </Tooltip>
                                        )}
                                        {!player.isChosen &&
                                            isPlayerInAnotherTeam(player.id, teams, selectedTeam, clubId) && (
                                                <Tooltip title={t('Events.playerInAnotherTeam') as string}>
                                                    <WarningIcon
                                                        color="warning"
                                                        fontSize="small"
                                                        style={{ marginLeft: 4 }}
                                                    />
                                                </Tooltip>
                                            )}
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell align="center">
                                <Box>{getYearOfBirth(player.bdat)}</Box>
                            </TableCell>
                            <TableCell align="center">{player.licenseNumber}</TableCell>
                            <TableCell align="center">
                                <Checkbox
                                    checked={player.isGoalkeeper}
                                    onChange={() => handleMultipleCheckboxChange(player.id, 'isGoalkeeper')}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <Checkbox
                                    checked={player.isCaptain}
                                    onChange={() => handleUniqueCheckboxChange(player.id, 'isCaptain')}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={player.number}
                                    onChange={e => {
                                        handleNumberChange(player.id, e.target.value);
                                    }}
                                    inputProps={{ min: 1, max: 99 }}
                                    error={
                                        isNumberTaken(players, player.number, player.id) ||
                                        (player.isChosen && player.number === '')
                                    }
                                    disabled={!player.isChosen}
                                    sx={{ width: '100px' }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={players?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};

function sortPlayers(players: IEventPlayer[], order: 'asc' | 'desc', column: keyof IEventPlayer) {
    const sortedPlayers = [...players].sort((a, b) => {
        if (a[column] === b[column]) return 0;
        if (typeof a[column] === 'string' && typeof b[column] === 'string') {
            return order === 'asc'
                ? (a[column] as string).localeCompare(b[column] as string)
                : (b[column] as string).localeCompare(a[column] as string);
        }
        return order === 'asc' ? (a[column] < b[column] ? -1 : 1) : a[column] > b[column] ? -1 : 1;
    });

    return sortedPlayers;
}

function changePlayerNumber(players: IEventPlayer[], id: string, value: string) {
    const changedPlayers = players.map(player => (player.id === id ? { ...player, number: value } : player));

    return changedPlayers;
}

function isNumberTaken(players: IEventPlayer[], num: string, id: string) {
    const isNumberTaken =
        num !== '' && players?.some(player => player.id !== id && player.isChosen && player.number === num);

    return isNumberTaken;
}

function isPlayerInAnotherTeam(
    playerId: string,
    teams: IEventTeam[],
    selectedTeam: IEventTeam | undefined | null,
    clubId: string
) {
    const isPlayerInAnotherTeam = teams.some(
        team =>
            team.club?.id === clubId &&
            team?.id !== selectedTeam?.id &&
            team.players.some(player => player.id === playerId)
    );

    return isPlayerInAnotherTeam;
}
