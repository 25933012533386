export const intl = {
    en: {
        'Transfers.tablehead.date': 'Date',
        'Transfers.tablehead.player': 'Player',
        'Transfers.tablehead.givingClub': 'Giving Club',
        'Transfers.tablehead.recivingClub': 'Receiving Club',
        'Transfers.tablehead.endDate': 'End Date',
        'Transfers.tablecell.atDate': 'At {{date}}',
        'Transfers.tablecell.fromDate': 'From {{date}}',
        'Transfers.tablecell.toDate': 'To {{date}}',
        'Transfers.filter.type': 'Type',
        'Transfers.filter.all': 'All',
        'Transfers.filter.transfer': 'Transfer',
        'Transfers.filter.loan': 'Loan',
        'Transfers.filter.player': 'Player',
        'Transfers.isLoan': 'Loan',
        'Transfers.saving.error': 'Error while saving transfer',
        'Transfers.saving.success': 'Transfer saved successfully',
        'Transfers.savingPlayer.error': 'Error while saving player',
        'Transfers.savingPlayer.success': 'Player saved successfully',
        'Transfers.title': 'Transfers',
    },
    uk: {
        'Transfers.tablehead.date': 'Дата',
        'Transfers.tablehead.player': 'Гравець',
        'Transfers.tablehead.givingClub': 'Клуб, з якого видали',
        'Transfers.tablehead.recivingClub': 'Клуб, до якого перевели',
        'Transfers.tablehead.endDate': 'Дата закінчення',
        'Transfers.tablecell.atDate': 'З {{date}}',
        'Transfers.tablecell.fromDate': 'З {{date}}',
        'Transfers.tablecell.toDate': 'До {{date}}',
        'Transfers.filter.type': 'Тип',
        'Transfers.filter.all': 'Всі',
        'Transfers.filter.transfer': 'Трансфер',
        'Transfers.filter.loan': 'Оренда',
        'Transfers.filter.player': 'Гравець',
        'Transfers.isLoan': 'Оренда',
        'Transfers.saving.error': 'Помилка при збереженні трансферу',
        'Transfers.saving.success': 'Трансфер збережено успішно',
        'Transfers.savingPlayer.error': 'Помилка при збереженні гравця',
        'Transfers.savingPlayer.success': 'Гравець збережено успішно',
        'Transfers.title': 'Трансфери',
    },
};
